<template>
  <div>
    <v-menu v-if="!showMassSelection" offset-y>
      <template v-slot:activator="{ on }">
        <mf-button :loading="loading" color="primary" label="Selecionar em massa" icon="library_add_check" fill-icon outlined v-on:click.native="on.click" />
      </template>
      <v-list>
        <v-list-item @click="$emit('clickOnEdit')">
          <v-list-item-content>Editar</v-list-item-content>
        </v-list-item>
        <v-list-item @click="$emit('clickOnReprocess')">
          <v-list-item-content>Reprocessar</v-list-item-content>
        </v-list-item>
        <v-list-item @click="$emit('clickOnDelete')">
          <v-list-item-content>Remover</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <mf-button
      v-else
      outlined
      color="primary"
      label="Cancelar"
      icon="close"
      iconColor="primary"
      iconPosition="left"
      :loading="loading"
      @click="$emit('cancel')"
    />
  </div>
</template>

<script>
export default {
  name: 'MassSelectionButton',
  props: {
    showMassSelection: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
